import Twitch from './Twitch';
import Playable from './Playable';
import Vimeo from './Vimeo';
import YouTube from './YouTube';
import DailyMotion from './DailyMotion';
import Facebook from './Facebook';

export default {
  dailymotion: DailyMotion,
  facebook: Facebook,
  playable: Playable,
  vimeo: Vimeo,
  twitch: Twitch,
  youtube: YouTube,
};
