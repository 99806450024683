export const TestIds = {
  cover: 'playable-cover',
  playable: 'playable',
  dailymotion: 'dailymotion',
  twitch: 'twitch',
  youtube: 'youtube',
  facebook: 'facebook',
  vimeo: 'vimeo',
  title: 'playable-title',
};
