import { usePrevious } from '../usePrevious';

/**
 * Calls effect callback in case value changed between renders
 * @param {T} value - value on current render - to store and compare
 * @param {(previousValue: T | null) => void} - callback to call on change. Takes previous value as single argument
 */
export function useChangedEffect<T>(
  value: T,
  effect: (previousValue: T | null) => void,
): void {
  const previousValue = usePrevious(value, value);
  if (value !== previousValue) {
    effect(previousValue);
  }
}
