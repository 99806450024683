import VideoPlayerComponent from '@wix/thunderbolt-elements/src/components/VideoPlayer/viewer/VideoPlayer';
import VideoPlayerController from '@wix/thunderbolt-elements/src/components/VideoPlayer/viewer/VideoPlayer.controller';


const VideoPlayer = {
  component: VideoPlayerComponent,
  controller: VideoPlayerController
};


export const components = {
  ['VideoPlayer']: VideoPlayer
};


// temporary export
export const version = "1.0.0"
