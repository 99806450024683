import * as React from 'react';

type Callback = () => void;
export type IProgress = {
  update: Callback;
  stop: Callback;
  subscribe: (listener: Callback) => void;
};
const useProgress = (): IProgress => {
  const timeout = React.useRef<number>(0);
  const listeners = React.useRef<Array<Callback>>([]);

  const stopProgress = () => {
    clearTimeout(timeout.current);
  };

  const updateProgress = () => {
    stopProgress();
    listeners.current.forEach(listener => listener());
    timeout.current = setTimeout(updateProgress, 1000) as unknown as number;
  };

  const subscribe = (listener: Callback) => {
    listeners.current.push(listener);
  };

  React.useEffect(() => {
    stopProgress();
  }, []);

  return { update: updateProgress, stop: stopProgress, subscribe };
};

export default useProgress;
