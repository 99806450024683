
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "PlayableCover847694237",
      {"classes":{"root":"PlayableCover847694237__root","responsiveMode":"PlayableCover847694237__responsiveMode","overlay":"PlayableCover847694237__overlay","title":"PlayableCover847694237__title","poster":"PlayableCover847694237__poster","playButton":"PlayableCover847694237__playButton"},"vars":{},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  