import * as React from 'react';
import { useAsyncRef } from '../../../../providers/useAsyncRef';
import { classes } from '../style/VideoPlayer.st.css';
import { useDidMount } from '../../../../providers/useDidMount';
import { useChangedEffect } from '../../../../providers/useChangedEffect';
import { TestIds } from '../constants';
import { getScriptOrLoad } from '../../../../providers/ScriptLoader/ScriptLoader';
import useProgress, { IProgress } from './useProgress';
import {
  IPlayer,
  IDailyMotionPlayer,
  IPlayerProps,
  IPlayerHandles,
} from './players.types';

const URL_REGEX =
  /^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:(?:dailymotion\.com(?:\/embed)?\/video)|dai\.ly)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?(?:\?.*)?$/;
const getVideoId = (src: string): string => src.match(URL_REGEX)![1];

const usePlayer = (
  container: React.MutableRefObject<HTMLDivElement | null>,
  config: any,
): [() => Promise<IDailyMotionPlayer>, () => IDailyMotionPlayer | void] => {
  const [waitForPlayer, getPlayer, setPlayer] =
    useAsyncRef<IDailyMotionPlayer>();

  useDidMount(() => {
    const waitForSDK = getScriptOrLoad('dailyMotion');
    waitForSDK.then((DM: any) => {
      setPlayer(new DM.player(container.current, config) as IDailyMotionPlayer);
    });
  });

  return [waitForPlayer, getPlayer];
};

function subscribeToEvents(
  getPlayer: () => IDailyMotionPlayer,
  {
    onReady,
    onPlay,
    onPause,
    onEnded,
    onDuration,
    onProgress,
    onFirstPlay,
    onFirstEnded,
    onError,
  }: Partial<IPlayerProps>,
  progress: IProgress,
  isPlayingNow: React.MutableRefObject<boolean>,
  firstPlayEnded: React.MutableRefObject<boolean>,
  firstPlayStarted: React.MutableRefObject<boolean>,
) {
  progress.subscribe(() => {
    onProgress?.(getPlayer().currentTime || 0);
  });

  return {
    apiready: () => onReady?.(),
    durationChange: () => onDuration?.(getPlayer().duration),
    playing: () => {
      if (!firstPlayStarted.current) {
        firstPlayStarted.current = true;
        onFirstPlay?.();
      }

      isPlayingNow.current = true;
      onPlay?.();

      progress.update();
    },
    pause: () => {
      isPlayingNow.current = false;
      onPause?.();

      progress.stop();
    },
    video_end: () => {
      if (!firstPlayEnded.current) {
        firstPlayEnded.current = true;
        onFirstEnded?.();
      }

      isPlayingNow.current = false;
      onEnded?.();

      progress.stop();
    },
    error: (event: Error) => onError && onError(event),
  };
}

const useChangedPropsEffects = (
  { src, playing, muted, volume, controls }: Partial<IPlayerProps>,
  firstPlayStarted: React.MutableRefObject<boolean>,
  firstPlayEnded: React.MutableRefObject<boolean>,
  waitForPlayer: () => Promise<IDailyMotionPlayer>,
) => {
  useChangedEffect(src, () => {
    firstPlayStarted.current = false;
    firstPlayEnded.current = false;
  });
  useChangedEffect(playing, () =>
    waitForPlayer().then(player => (playing ? player.play() : player.pause())),
  );
  useChangedEffect(muted, () =>
    waitForPlayer().then(player => player.setMuted(muted!)),
  );
  useChangedEffect(volume, () =>
    waitForPlayer().then(player => player.setVolume(volume! / 100)),
  );

  useChangedEffect(controls, () =>
    waitForPlayer().then(player => player.setControls(controls!)),
  );
};

const getHandles = (
  waitForPlayer: () => Promise<IDailyMotionPlayer>,
  getPlayer: () => IDailyMotionPlayer | void,
  isPlayingNow: React.MutableRefObject<boolean>,
): IPlayerHandles => {
  const handles: IPlayerHandles = {
    play: () => waitForPlayer().then(player => player.play()),
    pause: () => waitForPlayer().then(player => player.pause()),
    togglePlay: () => (isPlayingNow.current ? handles.pause() : handles.play()),
    getDuration: () => {
      const player = getPlayer();
      return player ? player.duration || 0 : 0;
    },
    getCurrentTime: () => {
      const player = getPlayer();
      return player ? player.currentTime || 0 : 0;
    },
    seekTo: time => waitForPlayer().then(player => player.setCurrentTime(time)),
    getVolume: () => {
      const player = getPlayer();
      return player ? player.volume * 100 : 0;
    },
    setVolume: fraction =>
      waitForPlayer().then(player => player.setVolume(fraction / 100)),
    isMuted: () => {
      const player = getPlayer();
      return player ? player.muted : true;
    },
    isPlaying: () => isPlayingNow.current,
    mute: () => waitForPlayer().then(player => player.setMuted(true)),
    unMute: () => waitForPlayer().then(player => player.setMuted(false)),
  };

  return handles;
};

const Player: IPlayer = (props, ref) => {
  const {
    src,
    playing,
    muted,
    controls,
    volume = 0,
    onReady,
    onInit,
    onDuration,
    showTitle,
    onProgress,
    onPlay,
    onPause,
    onEnded,
    onFirstPlay,
    onFirstEnded,
    onError,
  } = props;

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const isPlayingNow = React.useRef<boolean>(false);
  const firstPlayStarted = React.useRef<boolean>(false);
  const firstPlayEnded = React.useRef<boolean>(false);

  const progress = useProgress();

  const videoId = getVideoId(src as string);

  const [waitForPlayer, getPlayer] = usePlayer(containerRef, {
    width: '100%',
    height: '100%',
    video: videoId,
    params: {
      controls,
      autoplay: playing,
      mute: muted,
      'ui-start-screen-info': showTitle,
      origin:
        typeof window !== 'undefined' &&
        window.location &&
        window.location.origin,
    },
    events: subscribeToEvents(
      () => getPlayer() as IDailyMotionPlayer,
      {
        onReady,
        onPlay,
        onPause,
        onEnded,
        onDuration,
        onProgress,
        onFirstPlay,
        onFirstEnded,
        onError,
      },
      progress,
      isPlayingNow,
      firstPlayEnded,
      firstPlayStarted,
    ),
  });

  useDidMount(() => {
    waitForPlayer().then(player => {
      onInit?.(player, 'dailymotion');
    });
  });

  useChangedPropsEffects(
    { src, playing, muted, volume, controls },
    firstPlayStarted,
    firstPlayEnded,
    waitForPlayer,
  );

  React.useImperativeHandle(ref, () =>
    getHandles(waitForPlayer, getPlayer, isPlayingNow),
  );

  return (
    <div
      className={classes.playerContainer}
      data-player-name="DailyMotion"
      data-testid={TestIds.dailymotion}
    >
      <div ref={containerRef} />
    </div>
  );
};

export default React.forwardRef(Player);
